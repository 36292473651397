<template>
    <div class="get_results">
       <div class="back_title">
            支付结果
        </div>
        <div class="overFlow_box">
            <div class="pay_msg_box">
                <span><img src="../../assets/success_icon.png" alt=""></span>
                <span>支付成功</span>
            </div>
            <!-- <div class="btn_box">
                <van-button type="default">确认</van-button>
            </div> -->
        </div>
            
    </div>
</template>
<script>
import { Toast } from 'vant';
    export default {
        data () {
            return {
                // imgSrc: '',
                // coverUrl: '',
                // lessonName: '',
                // teacher: '',
                // chapterNum: '',
                // price: 0,
                // initNum: '',
                // textVal: '',
                // typeText: '',
                // phone: '',
            }
        },
        created() {
            // this.getContet()
            // if(this.$route.query.status == 1 || this.$route.query.status == 2) {
            //     this.imgSrc = require('../../assets/frown.png')
            //     this.typeText = '失败'
            // } else {
            //     this.imgSrc = require('../../assets/smile.png')
            //     this.typeText = '成功'
            // }
            //  this.$forceUpdate()
        },
        watch: {
            // $route: function(n, o) {
            //     console.log( n, o,'$$$$$$$$$$$$$$$$$$$$')
            //     // o.query = n.query
            //     if(n.query.status == 1) {
            //         this.textVal = `您已经是门道老用户了，无法通过该方式领取哦！您可以在门道APP找到这个课程，邀请满5个好友即可免费获得本课程哦`
            //         this.imgSrc = require('../../assets/frown.png')
            //         this.typeText = '失败'
            //     } else if(n.query.status == 2) {
            //         this.textVal = `您的好友已经满团您无法再领取了哦！可以在门道APP找到这个课程，自己邀请满5个好友即可免费获得本课程哦！`
            //         this.imgSrc = require('../../assets/frown.png')
            //         this.typeText = '失败'
            //     } else if(n.query.status == 3) {
            //         this.textVal = `预领取成功！让邀请您的上级好友再邀请满${this.$route.query.num}个用户，您就可在门道APP我的课程里观看该课程`
            //         this.imgSrc = require('../../assets/smile.png')
            //         this.typeText = '成功'
            //     } else if(n.query.status == 4) {
            //         this.textVal = `您可在门道APP我的课程里观看该课程`
            //         this.imgSrc = require('../../assets/smile.png')
            //         this.typeText = '成功'
            //     }
            //     this.lessonName = n.query.lessonName
            //     this.teacher = n.query.teacher
            //     this.chapterNum = n.query.chapterNum
            //     this.price = n.query.price
            //     this.initNum = n.query.initNum
            //     this.coverUrl = n.query.coverUrl
            //     this.phone = n.query.phone
            // }
        },
        methods: {
            openApp() {
                this.$http.formData(this.$api.getApiAddress('/home/h5/uploadApp', 'CESHI_API_HOST'), {
                    phone: this.phone
                }).then((res) => {  
                    if(res.data.code == 200) {
                        Toast(res.data.msg);
                    } else {
                        Toast(res.data.msg);
                    }
                })
                window.location.href = 'https://sj.qq.com/myapp/detail.htm?apkName=com.tencent.mobileqq'
            },
            getContet() {
                if(this.$route.query.status == 1) {
                    this.textVal = `您已经是门道老用户了，无法通过该方式领取哦！您可以在门道APP找到这个课程，邀请满5个好友即可免费获得本课程哦`
                } else if(this.$route.query.status == 2) {
                    this.textVal = `您的好友已经满团您无法再领取了哦！可以在门道APP找到这个课程，自己邀请满5个好友即可免费获得本课程哦！`
                } else if(this.$route.query.status == 3) {
                    this.textVal = `预领取成功！让邀请您的上级好友再邀请满${this.$route.query.num}个用户，您就可在门道APP我的课程里观看该课程`
                } else if(this.$route.query.status == 4) {
                    this.textVal = `您可在门道APP我的课程里观看该课程`
                }
                this.lessonName = this.$route.query.lessonName
                this.teacher = this.$route.query.teacher
                this.chapterNum = this.$route.query.chapterNum
                this.price = this.$route.query.price
                this.initNum = this.$route.query.initNum
                this.coverUrl = this.$route.query.coverUrl
                this.phone = this.$route.query.phone
               
            },
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>
<style  lang='scss' scoped>

.get_results {
    background:#ffffff;
    width: 100%;
    height: 100%;
    .back_title {
        width: 100%;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 34px;
        font-weight: 600;
        color: #000000;
        position: relative;
        i {
            position: absolute;
            left: 24px;
            color: #000000;
        }
    }
   
   .overFlow_box {
        background: #ffffff;
        //height: 100%;
        .pay_msg_box {
            span {
                display: block;
                width: 100%;
                text-align: center;
                img {
                    width: 186px;
                    height: 186px;
                }
            }
            
            span:nth-child(1) {
                font-size: 60px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 600;
                color: #333333;
                margin-top: 66px;
            }
            span:nth-child(2) {
                font-size: 32px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #313131;
                margin-top: 34px;
            }
            span:nth-child(3) {
                font-size: 32px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #969696;
                margin-top: 6px;
            }

        }
        .btn_box {
            // margin-left: 50%;
            // transform: translate(-50%);
            width: 100%;
            position: absolute;
            bottom: 40px;
            .van-button {
                width: 90%;
                display: block;
                margin-left: 50%;
                transform: translate(-50%);
                height: 48px;
                background: #333333;
                border-radius: 6px;
                font-size: 15px;
                font-family: STHeitiSC-Medium, STHeitiSC;
                font-weight: 500;
                color: #FFFFFF;
            }
            .pay_resault {
                display: block;
                width: 100%;
                text-align: center;
                margin-top: 14px;
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #969696;
                cursor: pointer;
            }
        }
    }
}
</style>